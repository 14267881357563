import { Fragment } from "react";
import i18next from "i18next";

import classes from "./LiveSelectForm.module.css";

function LiveSelectForm(props) {
  const {
    showAboutLive, 
    setShowAboutLive,
    useLiveInput,
    useLiveInputHandler,
  } = props;

  return (
    <Fragment>
      <div className={classes.control}>
        <div>
        {!useLiveInput && (<div>{i18next.t('trialText19', 'OR')},</div>)}
          <label>
            {i18next.t('roomFormText63', 'Use Live Broadcast')} (live broadcast)
            {!useLiveInput && (
              <span className={classes.liveAboutButton}
                onClick={() => { setShowAboutLive(!showAboutLive); }}>
                  {i18next.t('roomFormText64', 'About live broadcast')} ? &#9662;
              </span>
            )}
          </label>
        </div>
      </div>

      {!useLiveInput && showAboutLive && (
        <div>
          {i18next.t('roomFormText65', 'When you use Live, you can display your talk in both REMEET-WePL app and in watakura.xyz.')} {i18next.t('roomFormText66', 'There is no limit for participant number. Displaying in watakura.xyz may enable you to show your talk to wider audiances.')}
        </div>
      )}

      <div>
        <input className={classes.liveCheckbox} type="checkbox" id="useLive" name="interest" value="useLive"
          onChange={useLiveInputHandler} defaultChecked={useLiveInput}        
        />
        <label className={classes.liveCheckboxLabel} htmlFor="useLive">Use Live</label>
      </div>
      {/* <div>
        {useLiveInput ? `Use Live` : ''}
      </div> */}
    </Fragment>
  );
}

export default LiveSelectForm;
