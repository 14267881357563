import { Fragment, useRef, useState, useEffect } from "react";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Backdrop from '../../components/backdrop/Backdrop';
import Card from "../../components/ui/Card";
import Loader from "../../components/Loader/Loader";
import LiveSelectForm from "../../components/new-meetup/LiveSelectForm";
import Modal from '../../components/modal/Modal';
import MessageForSubscription from "../../components/new-meetup/MessageForSubscription";
import NewMeetupModalContent from '../../components/modal/NewMeetupModalContent';
import {
  createShortISOTime,
  getApi,
  sendRoomCreateMail,
  createLocalTime,
  isValidEmail,
  getRoomConfig,
  dateTimeDisplayOutput,
  transformLocaleDate,
} from "../../utils/new-meetup";
import { createRoom } from '../../utils/meetup';
import { createTextContent } from '../../utils/mail';
import { getSubscriptionState, getCurrentSubscription } from '../../utils/stripe';
import { useStore } from '../../hooks-store/store';

import classes from "./NewMeetupForm.module.css";

import { BACKEND_URL, subscriptionMiniPriceId } from '../../App';
// const BACKEND_URL = "http://localhost:4000";

function NewMeetupForm(props) {
  // console.log(props);
  // console.log(i18next);

  // const currentUrl = new URL(window.location.href);
  // const queryParams = currentUrl.searchParams;
  // const qCalendarDate = queryParams.get('calendardate');
  
  const qCalendarDate = props.calendardate;
  // console.log('qCalendarDate', qCalendarDate)

  const nowShortISOTime = createShortISOTime(Date.now());
  const maxDuration = 1000 * 60 * 60 * 24; // for example 1 day
  const allowStartTime = createShortISOTime(
    Date.now() + 1000 * 60 * 60 * 24 * 90
  ); // for example 90 days later

  const hourList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 
    13, 14, 15, 16,17, 18, 19, 20, 21, 22, 23
  ];

  const userLoginEmail = localStorage.getItem('email') ? localStorage.getItem('email') : '';


  const [store, disptch] = useStore();

  // const router = useRouter();

  // const { t } = useTranslation("common");

  const [roomNameInput, setRoomNameInput] = useState("");
  
  const [startInput, setStartInput] = useState(undefined);
  const [startDateInput, setStartDateInput] = useState(undefined);
  const [startHourInput, setStartHourInput] = useState(undefined);
  const [startMinuteInput, setStartMinuteInput] = useState(undefined);
  const [startXMInput, setStartXMInput] = useState('AM');
  
  const [endInput, setEndInput] = useState(undefined);
  const [endDateInput, setEndDateInput] = useState(undefined);
  const [endHourInput, setEndHourInput] = useState(undefined);
  const [endMinuteInput, setEndMinuteInput] = useState(undefined);
  const [endXMInput, setEndXMInput] = useState('AM');

  // const [emailInput, setEmailInput] = useState("");
  const [emailInput, setEmailInput] = useState(userLoginEmail);
  const [maxPeopleInput, setMaxPeopleInput] = useState(1);
  const [useLiveInput, setUseLiveInput] = useState('');

  const [roomCreateData, setRoomCreateData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [createMessage, setCreateMessage] = useState('');
  const [error, setError] = useState('');

  const [endInputError, setEndInputError] = useState('');

  const [showAboutLive, setShowAboutLive] = useState(false);

  // const [roomConfig, setRoomConfig] = useState(undefined);

  
  const roomConfig = store.roomConfig;

  const subscriptions = store.stripeData.subscriptions;
  const subscriptionStatus = getSubscriptionState(subscriptions);
  const currentSubscription = getCurrentSubscription(subscriptions);
  // console.log('currentSubscription', currentSubscription);

  let haveActiveSubscription = false;
  if (subscriptionStatus === 'active' || subscriptionStatus === 'trialing') {
    haveActiveSubscription = true;
  }

  let isMiniSubscripiton = false;
  if (currentSubscription && currentSubscription.plan.id === subscriptionMiniPriceId) {
    isMiniSubscripiton = true;
  }

  let trialEndTime;
  if (subscriptionStatus === 'trialing' && currentSubscription) {
    trialEndTime = currentSubscription.trial_end * 1000;
  }
  // console.log('trialEndTime', trialEndTime);

  // console.log(currentSubscription);

  // console.log(subscriptionStatus);

  //// initial load path did not work on deploy why?...
  // useEffect(() => {
  //   const currentUrl = new URL(window.location.href);
  //   // console.log(currentUrl, currentUrl.pathname);
  //   if (currentUrl.pathname === '/edit-room') {
  //     router.push('/edit-room');
  //   }
  // },[]);

  useEffect(() => {
    if (!props.isInModal) {
      document.title = 'Create New Meeting Room';
    }

    console.log('startDateInput: ', startDateInput)
    if (startDateInput && startHourInput && startMinuteInput) {

      const timeElement = startHourInput + ':' + startMinuteInput;

      console.log('start time:', timeElement);
      
      const localTime = createLocalTime(startDateInput, timeElement);
      
      const dateTimeEl = new Date(localTime)
      console.log(localTime, dateTimeEl);

      if (dateTimeEl) {
        console.log(dateTimeEl.getTime())
        setStartInput(dateTimeEl.getTime())
      }
    }
  }, [startDateInput, startHourInput, startMinuteInput, startXMInput]);


  useEffect(() => {
    console.log('endDateInput: ', endDateInput)
    if (endDateInput && endHourInput && endMinuteInput) {

      const timeElement = endHourInput + ':' + endMinuteInput;

      console.log('end time:', timeElement);
      
      const localTime = createLocalTime(endDateInput, timeElement);
      
      const dateTimeEl = new Date(localTime)
      console.log(localTime, '\n', dateTimeEl);

      if (dateTimeEl) {
        console.log(dateTimeEl.getTime(), new Date(dateTimeEl.getTime()))
        setEndInput(dateTimeEl.getTime());

        //// end input error
        // if (dateTimeEl.getTime() > Date.now() + roomConfig.endFutureLimit) {
        //   // setEndInputError('End time should be in 365 days from now');
        // }
        if (dateTimeEl.getTime() < Date.now()) {
          setEndInputError('End time should be after now');
        }
        else {
          setEndInputError('');
        }

      }
    }
  }, [endDateInput, endHourInput, endMinuteInput, endXMInput]);

  //// reset results when inputs are changed 
  useEffect(() => {
    setCreateMessage('');
    setError('');
  },[roomNameInput, startInput, maxPeopleInput, emailInput])

  useEffect(() => {
    if (qCalendarDate) {
      // console.log('qCalendardate', qCalendarDate);
      setStartDateInput(qCalendarDate);
      setEndDateInput(qCalendarDate);
    }
  },[]);


  const roomData = {
    roomId: roomNameInput,
    start: startInput,
    end: endInput,
    // maxPeople: maxPeopleInput,
    maxPeople: Math.round(maxPeopleInput),
    useLive: useLiveInput,
    
    email: emailInput,

    startLocalTime: startDateInput + ', ' + startHourInput + ':' + startMinuteInput + ' (' + new Date(startInput).toLocaleString() +')', // + ' (' + new Date(startInput).toLocaleString() + ')',
    endLocalTime: endDateInput + ', ' + endHourInput + ':' + endMinuteInput + ' (' + new Date(endInput).toLocaleString() +')', //  + ' (' + new Date(endInput).toLocaleString() + ')',
    // startLocalTime: dateTimeDisplayOutput(startInput),
    // endLocalTime: dateTimeDisplayOutput(endInput),

    subscriptionStatus: subscriptionStatus || '',
    trialEnd: currentSubscription && currentSubscription.trial_end * 1000,
    subscriptionPriceId: currentSubscription && currentSubscription.plan.id,
  };

  // console.log('roomData', roomData);


  const modalCloseHandler = () => {
    setShowModal(false);
  };

  const modalOpenHandler = () => {
    setShowModal(true);
  }

  const roomNameInputHandler = (event) => {
    setRoomNameInput(event.target.value.trim().toLowerCase());
  };

  const startDateInputHandler = (event) => {
    console.log(event.target.value);

    // some time validation logic and setState
    setStartDateInput(event.target.value);
  };

  const startHourInputHandler = (event) => {
    console.log(event.target.value);
    const hNum = Number(event.target.value);
    console.log(hNum);

    const inHourList = hourList.includes(hNum);
    console.log(inHourList);
    if (!inHourList) {
      setStartHourInput('');
      return;
    }
    if (hNum === 0) {
      setStartHourInput('00');
      return;
    }
    if (hNum < 10) {
      // console.log('0' + event.target.value);
      setStartHourInput('0' + hNum.toString());
    }
    else {
      setStartHourInput(event.target.value);
    }
  }

  const endDateInputHandler = (event) => {
    console.log(event.target.value);

    // some time validation logic and setState
    setEndDateInput(event.target.value);
  };

  const endHourInputHandler = (event) => {
    console.log(event.target.value);
    const hNum = Number(event.target.value);
    console.log(hNum);

    const inHourList = hourList.includes(hNum);
    console.log(inHourList);
    if (!inHourList) {
      setEndHourInput('');
      return;
    }
    if (hNum === 0) {
      setEndHourInput('00');
      return;
    }
    if (hNum < 10) {
      // console.log('0' + event.target.value);
      setEndHourInput('0' + hNum.toString());
    }
    else {
      setEndHourInput(event.target.value);
    }
  }

  const maxPeopleInputHandler = (event) => {
    setMaxPeopleInput(event.target.value);
  };

  const emailInputHandler = (event) => {
    setEmailInput(event.target.value);
  };

  const useLiveInputHandler = (event) => {
    if (useLiveInput) {
      setUseLiveInput('');
    } else {
      setUseLiveInput('useLive');
    }
  };

  const isValidInputs = () => {
    if (!roomConfig) {
      return false;
    }
    if (
      !roomNameInput ||
      roomNameInput.length < roomConfig.roomIdMin || 
      roomNameInput.length > roomConfig.roomIdMax ||
      !startInput ||
      !endInput ||
      endInput <= startInput ||
      endInput <= Date.now() || 
      endInput > Date.now() + roomConfig.endFutureLimit ||
      !maxPeopleInput ||
      // maxPeopleInput < 1 || maxPeopleInput > 100 ||
      (!useLiveInput && maxPeopleInput < 1) || (!useLiveInput && maxPeopleInput > 10) ||
      // !emailInput ||
      !isValidEmail(emailInput) ||
      endInput - startInput > roomConfig.maxDuration ||
      endInputError
    ) {
      return false;
    }
    return true;
  };


  const createRoomHandler = (enteredMeetupData) => {
    console.log(enteredMeetupData);
    setIsLoading(true);
    setCreateMessage('');
    setError('');

    let createdRoomData;

    getApi(BACKEND_URL)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);

        return createRoom(BACKEND_URL, enteredMeetupData);
      })
      // .then((resData) => {
      //   console.log(resData);

      //   createdRoomData = resData.data;
      //   // setRoomCreateData(resData.data);

      //   // setIsLoading(false);
      //   // modalCloseHandler();
      //   const subjectContent = `REMEET-WePL Presentation ${i18next.t("roomFormText51", 'Room reservation result')}`;

      //   const textContent = createTextContent(
      //     resData.data.roomId,
      //     resData.data.password, 
      //     resData.data.startLocalTime,
      //     resData.data.endLocalTime,
      //     resData.data.maxPeople,
      //     resData.data.email,
      //     resData.data.creatorPass,
      //     resData.data.useLive,
      //   );

      //   return sendRoomCreateMail(
      //     BACKEND_URL, 
      //     resData.data,
      //     textContent,
      //     subjectContent
      //   );
      // })
      .then(response => {
        console.log(response);
        setIsLoading(false);
        modalCloseHandler();

        if (props.isInModal && props.getCreatedRoomDataHandler && 
              props.hideCreateRoomModalHandler) {
          props.getCreatedRoomDataHandler(createdRoomData);

          setTimeout(() => {
            props.hideCreateRoomModalHandler();
          },1000*10);
        }

        setCreateMessage(
          <div>
            <div>
              {/* Room Reservation Success! */}
              {i18next.t("roomFormText30")}
            </div>
            <div>
              {/* Result is sent to your email address */}
              {i18next.t("roomFormText31")}
            </div>
          </div>
        );
      })
      .catch((err) => {
        console.log(err, err.errorType);

        if (err.message === 'get-api-failed') {
          setError(i18next.t("roomFormText19", 
            'Service is not available now'
          ));
        }

        // if (err.message === 'send-mail-failed') {
        //   setError(i18next.t("roomFormText19", 
        //     'Service is not available now'
        //   ));

        //   fetch(BACKEND_URL + "/room", {
        //     method: "DELETE",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(createdRoomData),
        //   }).then(res => {
        //     return res.json();
        //   }).then(resData => {
        //     console.log(resData);
        //   }).catch(err => {
        //     console.log(err);
        //   });
        // }

        if (err.errorType && err.errorType === 'roomId-exist') {
            setError(i18next.t('roomFormText59',
              `Entered room's name is already used by another user in that time frame. Please try different room's name`
            ));
        }

        else {
          // setError(err.message);
          setError(i18next.t("roomFormText20", 
            'Room creation failed! Please check your Inputs'
          ));

        }

        // setError(err.message);
        setIsLoading(false);
        modalCloseHandler();
        
      });
  };




  let body;
    body = (
      <Card>

        {/* i18n-test-{t("h1")} */}

        <div className={classes.form}>
          <div className={classes.formTitle}>
            {/* Room Creation Form for presentation*/}
            {i18next.t("roomFormText1")} {i18next.t("roomFormText61", 'for Presentation and Talk')}
          </div>

          <div className={classes.control}>
            <label htmlFor="room-name">
              {/* Room Name (8-20 character length of lowercase or number) {roomNameInput && roomNameInput.length} */}
              Room Name {i18next.t("roomFormText2")}  {roomNameInput && roomNameInput.length}
            </label>
            <input
              type="text"
              required
              value={roomNameInput}
              onChange={roomNameInputHandler}
              placeholder='example: next123talk'
            />
          </div>
          <div className={classes.control}>
            <label htmlFor="start-date-time">
              {/* Start Time, up to one year later (Start Date and Time) */}
              {i18next.t("roomFormText3")}, {i18next.t("roomFormText57", 'up to one month later')} (Start Date and Time)
            </label>
            <input 
              type='date'
              onChange={startDateInputHandler}
              // value='2021-05-22'
              defaultValue={qCalendarDate && startDateInput}
              min={transformLocaleDate(Date.now())}
              max={transformLocaleDate(Date.now() + store.roomConfig.endFutureLimit - 1000*60*60*24)}
              // max='2022-02-10'
            />

          </div>
          <div className={classes.timeInputContainer}>
            {/* <span className={classes.timeSelect}>Time</span> */}
            {/* <input 
              className={classes.timeInput} 
              type="text"
              placeholder='0~23' 
              onChange={startHourInputHandler}
            /> */}
            <select className={classes.timeSelect} name="hour" id="hour"
              onChange={(event) => {setStartHourInput(event.target.value)}}
              value={startHourInput}
            >
                <option value="">H</option>
                <option value="00">00 (12 AM)</option>
                <option value="01">01 (1 AM)</option>
                <option value="02">02 (2 AM)</option>
                <option value="03">03 (3 AM)</option>
                <option value="04">04 (4 AM)</option>
                <option value="05">05 (5 AM)</option>
                <option value="06">06 (6 AM)</option>
                <option value="07">07 (7 AM)</option>
                <option value="08">08 (8 AM)</option>
                <option value="09">09 (9 AM)</option>
                <option value="10">10 (10 AM)</option>
                <option value="11">11 (11 AM)</option>
                <option value="12">12 (12 PM)</option>
                <option value="13">13 (1 PM)</option>
                <option value="14">14 (2 PM)</option>
                <option value="15">15 (3 PM)</option>
                <option value="16">16 (4 PM)</option>
                <option value="17">17 (5 PM)</option>
                <option value="18">18 (6 PM)</option>
                <option value="19">19 (7 PM)</option>
                <option value="20">20 (8 PM)</option>
                <option value="21">21 (9 PM)</option>
                <option value="22">22 (10 PM)</option>
                <option value="23">23 (11 PM)</option>
            </select>

            <span className={classes.timeSeparator}>:</span>
            <select className={classes.timeSelect} name="minutes" id="minutes"
              onChange={(event) => {setStartMinuteInput(event.target.value)}}
              value={startMinuteInput}
            >
              <option value="">M</option>
              <option value="00">00</option>
              <option value="30">30</option>
            </select>
            <span> </span>
            {/* <select className={classes.timeSelect} name="xm" id="xm"
              onChange={(event) => {setStartXMInput(event.target.value)}}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select> */}
          </div>

          <div className={classes.control}>
            {/* {startDateInput}{', '} {startHourInput} {':'} {startMinuteInput}
            <br/> */}
            {/* {startInput 
              ? startDateInput + ' ' + new Date(startInput).toTimeString().split(' ')[0] 
                + ' (' + new Date(startInput).toLocaleTimeString() + ')'
              : null
            } */}
            {startInput
              && dateTimeDisplayOutput(startDateInput, startInput)
            }
            {/* <br/>
            {startInput && new Date(startInput).toString()} */}
          </div>

          {trialEndTime && startInput > trialEndTime
            && (<div className={classes.inputError}>
              {i18next.t("subText45", "Start Time should be before end of free trial subscription.")}
            </div>)
          }

          <div className={classes.control}>
            <label htmlFor="start-date-time">
              {/* End Time, up to one year later (End Date and Time) */}
              {/* {i18next.t("roomFormText4")}, {i18next.t("roomFormText57", 'up to one year later')} (End Date and Time) */}
              {/* End Time, maximum 6 hours (End Date and Time) */}
              {i18next.t("roomFormText4")}, maximum 6 hours (End Date and Time) 
            </label>
            <input 
              type='date'
              onChange={endDateInputHandler}
              defaultValue={qCalendarDate && endDateInput}
              min={transformLocaleDate(Date.now())}
              max={transformLocaleDate(Date.now() + store.roomConfig.endFutureLimit - 1000*60*60*24)}
              // value='2021-05-22'
            />
          </div>
          <div className={classes.timeInputContainer}>
            {/* <span className={classes.timeSelect}>Time</span> */}
            {/* <input 
              className={classes.timeInput} 
              type="text"
              placeholder='0~23'
              onChange={endHourInputHandler}
            /> */}
            <select className={classes.timeSelect} name="hour" id="hour"
              onChange={(event) => {setEndHourInput(event.target.value)}}
              value={endHourInput}
            >
                <option value="">H</option>
                <option value="00">00 (12 AM)</option>
                <option value="01">01 (1 AM)</option>
                <option value="02">02 (2 AM)</option>
                <option value="03">03 (3 AM)</option>
                <option value="04">04 (4 AM)</option>
                <option value="05">05 (5 AM)</option>
                <option value="06">06 (6 AM)</option>
                <option value="07">07 (7 AM)</option>
                <option value="08">08 (8 AM)</option>
                <option value="09">09 (9 AM)</option>
                <option value="10">10 (10 AM)</option>
                <option value="11">11 (11 AM)</option>
                <option value="12">12 (12 PM)</option>
                <option value="13">13 (1 PM)</option>
                <option value="14">14 (2 PM)</option>
                <option value="15">15 (3 PM)</option>
                <option value="16">16 (4 PM)</option>
                <option value="17">17 (5 PM)</option>
                <option value="18">18 (6 PM)</option>
                <option value="19">19 (7 PM)</option>
                <option value="20">20 (8 PM)</option>
                <option value="21">21 (9 PM)</option>
                <option value="22">22 (10 PM)</option>
                <option value="23">23 (11 PM)</option>
            </select>
            <span className={classes.timeSeparator}>:</span>
            <select className={classes.timeSelect} name="minutes" id="minutes"
              onChange={(event) => {setEndMinuteInput(event.target.value)}}
              value={endMinuteInput}
            >
                <option value="">M</option>
                <option value="00">00</option>
                <option value="30">30</option>
            </select>
            <span> </span>
            {/* <select className={classes.timeSelect} name="xm" id="xm"
              onChange={(event) => {setEndXMInput(event.target.value)}}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select> */}
          </div>
          <div className={classes.control}>
            {/* {startDateInput}{', '} {startHourInput} {':'} {startMinuteInput}
            <br/> */}
            {/* {endInput 
              ?  endDateInput + ' ' + new Date(endInput).toTimeString().split(' ')[0]
              + ' (' + new Date(endInput).toLocaleTimeString() + ')'
              : null
            } */}
            {endInput
              && dateTimeDisplayOutput(endDateInput, endInput)
            }
          </div>

          {roomConfig && ((endInput - startInput) > roomConfig.maxDuration) &&
            <div className={classes.inputError}>
              {i18next.t("roomFormText71", 'maximum 6 hours')}
              </div>
          }

          <div className={classes.inputError}>{endInputError}</div>

          {!useLiveInput && (
            <div className={classes.control}>
              <label htmlFor="max-people">
                {i18next.t("roomFormText5", 'Maximum Participatns')}, {i18next.t("roomFormText62", 'less than 10')} (Maximum number of participants)
                {/* {i18next.t("roomFormText5")}, {i18next.t("roomFormText58", 'less than 100')} (Maximum number of participants) */}
              </label>
              <input
                type="number"
                required
                min="1"
                setp="1"
                value={maxPeopleInput}
                onChange={maxPeopleInputHandler}
              />
            </div>
          )}

          {/* {!userLoginEmail && maxPeopleInput > 3 &&
            <div>some-message-for-log-sub</div>
          } */}

          {/* {(!haveActiveSubscription && maxPeopleInput >= 3) &&
            <MessageForSubscription />
          } */}

          {/* {isMiniSubscripiton && maxPeopleInput > 6
            && (<div className={classes.inputError}>
                Mini Plan's {i18next.t("subText44", "maximum participant number is 6")}
              </div>)
          } */}

          <LiveSelectForm 
            showAboutLive={showAboutLive}
            setShowAboutLive={setShowAboutLive}
            useLiveInput={useLiveInput}
            useLiveInputHandler={useLiveInputHandler}
          />

          <div className={classes.control}>
            <label htmlFor="email">
              {/* Email address (Email address) */}
              {i18next.t("roomFormText6")} (Email address)
            </label>
            {userLoginEmail
              ? emailInput
              : <input
                  id="email"
                  type="email"
                  required
                  value={emailInput}
                  onChange={emailInputHandler}
                />
            }
            
          </div>
          {/* <div className={classes.control}>
          <label htmlFor='tel'>tes-input</label>
          <input 
            type='tel'
            // required 
          />
        </div> */}
          <div className={classes.actions}>
            <button
              disabled={!isValidInputs() || isLoading}
              onClick={() => {
                // props.onAddMeetup(roomData);
                // createRoomHandler(roomData);
                modalOpenHandler();
              }}
            >
              {/* Make Reservation */}
              {i18next.t("roomFormText7")} 
              
            </button>
          </div>

          {!isValidInputs() && 
            <div className={classes.actions}>
              {/* invalid-inputs */}
              {i18next.t("roomFormText54", 'Invalid Inputs')}
            </div>
          }
          
          
          {isLoading && <div><Loader /></div>}
          <div className={classes.resultMessage}>
            {error}
          </div>
          <div className={classes.resultMessage}>
            {createMessage}
          </div>

          {/* <div style={{overflow:"auto"}}>
            result-create
            {JSON.stringify(roomCreateData)}
          </div> */}
        </div>

      </Card>
    );


  return (
  <Fragment>
    {/* <div onClick={() => {
      const testText = createTextContent(
        roomData.roomId,
        roomData.password, 
        roomData.startLocalTime,
        roomData.endLocalTime,
        roomData.maxPeople,
        roomData.email,
        roomData.creatorPass,
        roomData.useLive,
      );
      console.log(testText);
    }}>text-test</div> */}
    {showModal && <Backdrop />}
    {showModal &&
      <Modal
        onConfirm={createRoomHandler}
        onCancel={modalCloseHandler}
        roomData={roomData}
        isLoading={isLoading}
        // setSelectAction={() =>{}}
      >
        {/* modal-content */}
        <NewMeetupModalContent 
          roomData={roomData}
          startDateInput={startDateInput}
          startHourInput={startHourInput}
          startMinuteInput={startMinuteInput}
          endDateInput={endDateInput}
          endHourInput={endHourInput}
          endMinuteInput={endMinuteInput}
        />
      </Modal>
    }


    {body}
  </Fragment>);
}

export default NewMeetupForm;
