import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";

import Card from "../../ui/Card";
import { authLoginPageLink, authSignupPageLink } from "../../../App";

import classes from "./AboutRemeetWeplApp.module.css";

import RemeetWeplImage1 from '../../../images/remeet-wepl-image1.png';
import RemeetWeplImage2 from '../../../images/remeet-wepl-image2.jpg';


function AboutRemeetWeplApp(props) {
  const userLoginEmail = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";

  const [showAboutRemeet, setShowAboutRemeet] = useState(false);

  useEffect(() => {
    if (!userLoginEmail) {
      setShowAboutRemeet(true);
    } else {
      setShowAboutRemeet(false);
    }
  },[]);

  return (
    <Fragment>
      <div>
        <div className={classes.aboutRemeetTitle}
          onClick={() => { setShowAboutRemeet(!showAboutRemeet); }}
        >
          <span>
            {i18next.t("aboutWepl.text01", "About App for Video Presentation and Talk")} &#9662;
            {' '}
            <br/>
            (REMEET-WePL: Webinar, Presentation, Live) 
          </span>
        </div>

        {showAboutRemeet && (
            <div className={classes.aboutRemeetContent}>
              <p>
              {i18next.t("aboutWepl.text02", "REMEET-WePL is a simple video presentation app. It includes text chat, screen share, and file share.")}
              </p>
              <p>
              {i18next.t("aboutWepl.text03", "Video presentation using REMEET-WePL allows users to show video talk to remote viewers.")}
                {' '}
                {i18next.t("aboutWepl.text04", "That is suitable for various situations such as video seminar, video presentation, remote work, and much more.")}
                {' '}
              </p>
              <p>
              {i18next.t("aboutWepl.text05", "Users of REMEET-WePL can communicate with viewers using text chat and file share.")}
              </p>
              <p>
              {i18next.t("aboutWepl.text06", "When enabled live broadcast, you can show your video talk at watakura.xyz's feed page.")}
                {' '}
                {i18next.t("aboutWepl.text07", "That may enable you to show your talk to wider audiances.")}    
              </p>
              <p>
              {i18next.t("aboutWepl.text08", "If you want to communicate with multiple people with video, you can use")} 
                {' '}<a href="https://remeet.watakura.xyz/new-room-from-above-link" target="_blank" rel="nofollow noopener noreferrer">
                  <strong>REMEET</strong>
                </a>.
              </p>
              <p>
                {i18next.t("aboutWepl.text09", "Start your next talk")}....
                {!userLoginEmail && (
                  <span>
                    {' '}
                    <a href={authSignupPageLink}>
                      {i18next.t("trialText32", "Signup")}
                    </a>
                    <span> or, </span>
                    <a href={authLoginPageLink}>
                      {i18next.t("trialText31", "Login")}
                    </a>
                  </span>
                )}
                {userLoginEmail && (
                  <span> 
                    {' '} {i18next.t("aboutWepl.text10", "from room creation form")}
                  </span>
                )}
              </p>

              <div className={classes.aboutRemeetImageContainer}>
              <img className={classes.aboutRemeetImage}
                src={RemeetWeplImage1}
                alt='remeet-img'
              />
              <img className={classes.aboutRemeetImage}
                src={RemeetWeplImage2}
                alt='remeet-img'
              />
            </div>
            </div>
        )}

      </div>
    </Fragment>
  );
}

export default AboutRemeetWeplApp;
