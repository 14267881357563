import { Fragment } from "react";
import i18next from "i18next";

import Loader from "../../Loader/Loader";

import classes from "./CancelModalContent.module.css";

function CancelModalContent(props) {
  // console.log('cancelModalContent-props', props);
  const { 
    setShowCancelModal, 
    handleClick, 
    errorMessage,
    isLoading,
   } = props;

  return (
    <Fragment>
      <div>
        <div className={classes.subscriptionModalTitle}>
          {i18next.t("subText29", "Please Confirm Your Action")}
        </div>
        <button
          className={classes.subscriptionCancelButton}
          disabled={isLoading}
          onClick={() => {
            setShowCancelModal(false);
          }}
        >
          {i18next.t("subText30", "Close")}
        </button>
        <button
          className={classes.subscriptionButton}
          disabled={isLoading}
          onClick={handleClick}
        >
          {i18next.t("subText31", "Cancel Subscription")}
        </button>

        {errorMessage && (
          <div>{errorMessage}</div>
        )}
        
        {isLoading && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default CancelModalContent;
