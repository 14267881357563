import { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';
import dateAndTime from 'date-and-time';

import UserRoomListItem from "../UserRoomList/UserRoomListItem";

import { useStore } from "../../../hooks-store/store";

// import './Sample.less';
import classes from './RoomDayModalContent.module.css';

// import classes from './UserRoomCalender.module.css';

function RoomDayModalContent(props) {
  const { 
    selectedDayRoomList, 
    hideRoomDayModalHandler,
    value,
    sameDay,
    showCreateRoomModalHandler,
  } = props;

  const [store, dispatch] = useStore();

  const roomForCreateLink = `/?calendardate=${dateAndTime.format(new Date(value), 'YYYY-MM-DD')}`
  
  let modalRoomListBody;

  if (selectedDayRoomList && selectedDayRoomList.length > 0) {
    modalRoomListBody = (
      <ul>
      {selectedDayRoomList.map((room, index) => {
        return (
          <li key={index}>
            <UserRoomListItem 
              room={room}
            />
          </li>
        );
      })}
    </ul>
    )
  }

  return (
    <Fragment>
      <div className={classes.roomDayModalClose}>
        <span className={classes.roomDayModalCloseButton}
          onClick={hideRoomDayModalHandler}
        >
          X
        </span>
      </div>
      {value && (
        <div>{new Date(value).toLocaleDateString()}</div>
      )}
      <div>
        {modalRoomListBody}
      </div>
      
      {(new Date(value).getTime() > Date.now() || sameDay) && (
        <div>
          <button
            onClick={showCreateRoomModalHandler}
          >
            {i18next.t('userRoom.text20', 'Create New Room')}
          </button>
        </div>
      )}
    </Fragment>
  );
}

export default RoomDayModalContent;
