import { Fragment } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";

import { authSignupPageLink } from "../../App";

import classes from "./MessageForSubscription.module.css";

function MessageForSubscription(props) {
  return (
    <Fragment>
      <div className={classes.message}>
        signup-message
        {/* {i18next.t('trialText16', 'Subscription is required for 3 and more than 3 participants')} */}
      </div>
      {/* <div className={classes.infoTitle}>
        <span>
          {i18next.t('trialText17', 'Start Free Trial Subscription')}:
        </span>
        {'  '}
        <Link to="/trial-information">
          {i18next.t('trialText18', 'Free Trial Subscription Info Page')}
        </Link>
      </div>
      <div className={classes.infoOR}>
        {i18next.t('trialText19', 'OR')}
      </div> */}
      <div className={classes.infoTitle}>
        {/* <span>
          {i18next.t('trialText20', 'Signup for Subscription')}:
        </span> */}
        {'  '}
        <span>
          <a
            href={authSignupPageLink} // target="_blank" rel="nofollow noopener noreferrer"
          >
            {i18next.t('trialText12', 'Signup Page')}
          </a>
        </span>
      </div>
      
      {/* <div>
        <a
          href={authSignupPageLink}
          // target="_blank" rel="nofollow noopener noreferrer"
        >
          <button className={classes.authLinkButton}>Signup Page</button>
        </a>
      </div> */}
    </Fragment>
  );
}

export default MessageForSubscription;
