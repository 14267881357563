import { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from "i18next";

import { useStore } from "../../../hooks-store/store";

import { roomPageUrl, weplPageUrl } from "../../../App";

import classes from "./UserRoomListItem.module.css";

function UserRoomListItem(props) {
  const { room } = props;
  // console.log(props);
  // console.log(i18next);

  const userLoginEmail = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";

  const [store, dispatch] = useStore();
  const userRoomList = store.userRoomStore.userRoomList;

  const roomforEditLink = `/edit-room?roomId=${room.roomId}&password=${room.password}&creatorPass=${room.creatorPass}`;

  let userRoomListItemBody;

  if (room) {
    userRoomListItemBody = (
      <div className={classes.userRoomListItem}>
        <div>
          {i18next.t('userRoom.text04', 'Name')}:
          {' '}
          <strong>{room.roomId}</strong>
        </div>
        <div>
          {i18next.t('userRoom.text05', 'Start time')}:
          {' '}
          <strong>{new Date(room.start).toLocaleString()}</strong>
        </div>
        <div>
          {i18next.t('userRoom.text06', 'End time')}:
          {' '}
          <strong>{new Date(room.end).toLocaleString()}</strong>
        </div>

        {room.useLive && (
          <div>
            <strong>
              {i18next.t('roomFormText63', 'Use Live Broadcast')} (live broadcast)
            </strong>
          </div>
        )}

        {!room.useLive && (
          <div>
            {i18next.t('userRoom.text07', 'Maximum participants')}: 
            {' '}
            <strong>{room.maxPeople}</strong>
          </div>
        )}

        {room.end > Date.now() && (
          <div>
            <a
              href={weplPageUrl + `/${room.roomId}`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {i18next.t('userRoom.text08', 'Room Page')}:
            </a>{" "}
            <div>
              ({i18next.t('userRoom.text09', 'Room will be available 10 minutes before start time')})
            </div>

            {room.start > Date.now() && (
              <div>
                <Link to={roomforEditLink} >
                  <button>
                    {i18next.t('userRoom.text19', 'Edit This Room')}
                  </button>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  // let userRoomListBody;

  // if (userRoomList.length > 0) {
  //   userRoomListBody = (
  //     <ul>
  //       {userRoomList.map((room, index) => {
  //         return (
  //           <li key={index}>
  //             roomId: {room.roomId}
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   );
  // }

  return <Fragment>{userRoomListItemBody}</Fragment>;
}

export default UserRoomListItem;
