import { Fragment, useRef, useState, useEffect } from "react";
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Backdrop from '../../components/backdrop/Backdrop';
import Card from "../../components/ui/Card";
import LiveSelectForm from "../../components/new-meetup/LiveSelectForm";
import Loader from "../../components/Loader/Loader";
import MessageForSubscription from "../../components/new-meetup/MessageForSubscription";
import Modal from '../../components/modal/Modal';
import EditMeetupModalContent from '../../components/modal/EditMeetupModalContent';
import GetMeetupForm from './GetMeetupForm';
import NewMeetupModalContent from '../../components/modal/NewMeetupModalContent';
import GetMeetupContent from './GetMeetupContent';

import {
  createShortISOTime,
  getApi,
  sendRoomCreateMail,
  createLocalTime,
  isValidEmail,
  transformLocaleDate,
  transFormLocaleDateTime,
  dateTimeDisplayOutput,
} from "../../utils/new-meetup";
import { deleteRoom, updateRoom } from '../../utils/meetup';
import { createTextContent } from '../../utils/mail';
import { getSubscriptionState, getCurrentSubscription } from "../../utils/stripe";
import { useStore } from '../../hooks-store/store';
import classes from "./EditMeetupForm.module.css";

import { BACKEND_URL, subscriptionMiniPriceId } from '../../App';
import { configure } from "@testing-library/react";

function EditMeetupForm(props) {

  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.searchParams;
  const qRoomId = queryParams.get('roomId');
  const qPassword = queryParams.get('password');
  const qCreatorPass = queryParams.get('creatorPass');


  const nowShortISOTime = createShortISOTime(Date.now());
  const maxDuration = 1000 * 60 * 60 * 24; // for example 1 day
  const allowStartTime = createShortISOTime(
    Date.now() + 1000 * 60 * 60 * 24 * 90
  ); // for example 90 days later

  const hourList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 
    13, 14, 15, 16,17, 18, 19, 20, 21, 22, 23
  ];

  const [store, disptch] = useStore();
  // const { t } = useTranslation("common");

  const [roomNameInput, setRoomNameInput] = useState('');
  
  const [startInput, setStartInput] = useState(undefined);
  const [startDateInput, setStartDateInput] = useState(undefined);
  const [startHourInput, setStartHourInput] = useState(undefined);
  const [startMinuteInput, setStartMinuteInput] = useState(undefined);
  const [startXMInput, setStartXMInput] = useState('AM');
  
  const [endInput, setEndInput] = useState(undefined);
  const [endDateInput, setEndDateInput] = useState(undefined);
  const [endHourInput, setEndHourInput] = useState(undefined);
  const [endMinuteInput, setEndMinuteInput] = useState(undefined);
  const [endXMInput, setEndXMInput] = useState('AM');

  const [emailInput, setEmailInput] = useState("");
  const [maxPeopleInput, setMaxPeopleInput] = useState(1);
  const [useLiveInput, setUseLiveInput] = useState('');

  const [roomCreateData, setRoomCreateData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [createMessage, setCreateMessage] = useState('');
  const [error, setError] = useState('');

  const [endInputError, setEndInputError] = useState('');

  const [gotRoomInfo, setGotRoomInfo] = useState(undefined);
  const [selectAction, setSelectAction] = useState('');

  const [showAboutLive, setShowAboutLive] = useState(false);

  const roomConfig = store.roomConfig;

  const subscriptions = store.stripeData.subscriptions;
  const subscriptionStatus = getSubscriptionState(subscriptions);
  const currentSubscription = getCurrentSubscription(subscriptions);
  // console.log('currentSubscription', currentSubscription);


  let haveActiveSubscription = false;
  if (subscriptionStatus === 'active' || subscriptionStatus === 'trialing') {
    haveActiveSubscription = true;
  }

  let isMiniSubscripiton = false;
  if (currentSubscription && currentSubscription.plan.id === subscriptionMiniPriceId) {
    isMiniSubscripiton = true;
  }

  let trialEndTime;
  if (subscriptionStatus === 'trialing' && currentSubscription) {
    trialEndTime = currentSubscription.trial_end * 1000;
  }
  // console.log('trialEndTime', trialEndTime);

  useEffect(() => {
    if (!qRoomId && !qPassword && !qCreatorPass) {
      setGotRoomInfo('');
    }
  },[]);

  useEffect(() => {
    document.title = 'Edit Presentation Room';
    ////pre fill room data
    if (gotRoomInfo) {

      console.log(gotRoomInfo);
      
      setRoomNameInput(gotRoomInfo.roomId);
      setStartInput(gotRoomInfo.start);
      setEndInput(gotRoomInfo.end);
      setMaxPeopleInput(gotRoomInfo.maxPeople);
      setUseLiveInput(gotRoomInfo.useLive);

      setEmailInput(gotRoomInfo.email);

      createGotStartFormat();
      createGotEndFormat();

      // console.log(new Date(gotRoomInfo.start).toLocaleDateString().split('/'));
      // console.log(new Date(gotRoomInfo.start).toString().split(' '));
    }
  },[gotRoomInfo]);

  useEffect(() => {
    console.log('startDateInput: ', startDateInput)
    if (startDateInput && startHourInput && startMinuteInput) {

      const timeElement = startHourInput + ':' + startMinuteInput;

      console.log('start time:', timeElement);
      
      const localTime = createLocalTime(startDateInput, timeElement);
      
      const dateTimeEl = new Date(localTime)
      console.log(localTime, dateTimeEl);

      if (dateTimeEl) {
        console.log(dateTimeEl.getTime())
        setStartInput(dateTimeEl.getTime())
      }
    }
  }, [startDateInput, startHourInput, startMinuteInput, startXMInput]);


  useEffect(() => {
    console.log('endDateInput: ', endDateInput)
    if (endDateInput && endHourInput && endMinuteInput) {

      const timeElement = endHourInput + ':' + endMinuteInput;

      console.log('end time:', timeElement);
      
      const localTime = createLocalTime(endDateInput, timeElement);
      
      const dateTimeEl = new Date(localTime)
      console.log(localTime, '\n', dateTimeEl);

      if (dateTimeEl) {
        console.log(dateTimeEl.getTime(), new Date(dateTimeEl.getTime()))
        setEndInput(dateTimeEl.getTime())

        //// end input error
        // console.log(roomConfig, dateTimeEl.getTime(), Date.now() + roomConfig.endFutureLimit);
        // if (dateTimeEl.getTime() > Date.now() + roomConfig.endFutureLimit) {
        //   console.log('365');
        //   setEndInputError('error');
        // }
        if (dateTimeEl.getTime() < Date.now()) {
          setEndInputError('End time should be after now');
        }
        else {
          setEndInputError('');
        }

      }
    }
  }, [endDateInput, endHourInput, endMinuteInput, endXMInput]);

  //// reset results when inputs are changed 
  useEffect(() => {
    setCreateMessage('');
    setError('');
  },[roomNameInput, startInput, maxPeopleInput, emailInput])

  const roomData = {
    roomId: gotRoomInfo ? gotRoomInfo.roomId : '',
    start: gotRoomInfo ? gotRoomInfo.start : '',
    end: gotRoomInfo ? gotRoomInfo.end : '',
    maxPeople: gotRoomInfo ? gotRoomInfo.maxPeople : '',
    useLive: gotRoomInfo ? gotRoomInfo.useLive: '',

    email: gotRoomInfo ? gotRoomInfo.email: '',
    password: gotRoomInfo ? gotRoomInfo.password : '',
    creatorPass: gotRoomInfo ? gotRoomInfo.creatorPass: '',

    newStart: startInput,
    newEnd: endInput,
    // newMaxPeople: maxPeopleInput,
    newMaxPeople: Math.round(maxPeopleInput),
    newUseLive: useLiveInput,

    newEmail: emailInput,

    newStartLocalTime: startDateInput + ', ' + startHourInput + ':' + startMinuteInput + ' (' + new Date(startInput).toLocaleString() +')', // + ' (' + new Date(startInput).toLocaleString() + ')',
    newEndLocalTime: endDateInput + ', ' + endHourInput + ':' + endMinuteInput + ' (' + new Date(endInput).toLocaleString() +')', //  + ' (' + new Date(endInput).toLocaleString() + ')',
    // newStartLocalTime: dateTimeDisplayOutput(startInput),
    // newEndLocalTime: dateTimeDisplayOutput(endInput),

    subscriptionStatus: subscriptionStatus,
    trialEnd: currentSubscription && currentSubscription.trial_end * 1000,
    subscriptionPriceId: currentSubscription && currentSubscription.plan.id,
  };

  // console.log('roomData', roomData);
  


  const createGotStartFormat = () => {
    if (gotRoomInfo) {
      console.log(new Date(gotRoomInfo.start).toLocaleString());
      console.log(new Date(gotRoomInfo.start).toString());
      console.log(new Date(gotRoomInfo.start).toLocaleString().split(' '));
      const dateElementList = new Date(gotRoomInfo.start).toLocaleDateString().split('/');
      if (Number(dateElementList[0]) < 10) {
        dateElementList[0] = '0' + dateElementList[0];
      }
      if (Number(dateElementList[1]) < 10) {
        dateElementList[1] = '0' + dateElementList[1];
      }
  
      const dateFormated = dateElementList[2] + '-' + dateElementList[0] + '-' + dateElementList[1];
      console.log(dateFormated);

      setStartDateInput(dateFormated);

      const timeElement = new Date(gotRoomInfo.start).toString().split(' ')[4];
      const hourElement = timeElement.split(':')[0];
      const minuteElement = timeElement.split(':')[1];
      console.log(timeElement, hourElement, minuteElement);

      setStartHourInput(hourElement);
      setStartMinuteInput(minuteElement);
    }
  };

  const createGotEndFormat = () => {
    if (gotRoomInfo) {
      console.log(new Date(gotRoomInfo.end).toLocaleString());
      console.log(new Date(gotRoomInfo.end).toString());
      console.log(new Date(gotRoomInfo.end).toLocaleString().split(' '));
      const dateElementList = new Date(gotRoomInfo.end).toLocaleDateString().split('/');
      if (Number(dateElementList[0]) < 10) {
        dateElementList[0] = '0' + dateElementList[0];
      }
      if (Number(dateElementList[1]) < 10) {
        dateElementList[1] = '0' + dateElementList[1];
      }
  
      const dateFormated = dateElementList[2] + '-' + dateElementList[0] + '-' + dateElementList[1];
      console.log(dateFormated);

      setEndDateInput(dateFormated);
  
      const timeElement = new Date(gotRoomInfo.end).toString().split(' ')[4];
      const hourElement = timeElement.split(':')[0];
      const minuteElement = timeElement.split(':')[1];
      console.log(timeElement, hourElement, minuteElement);

      setEndHourInput(hourElement);
      setEndMinuteInput(minuteElement);
    }
  };

  const modalCloseHandler = () => {
    setShowModal(false);
  };

  const modalOpenHandler = () => {
    setShowModal(true);
  }

  const roomNameInputHandler = (event) => {
    setRoomNameInput(event.target.value.trim().CardtoLowerCase());
  };

  const startDateInputHandler = (event) => {
    console.log(event.target.value);

    // some time validation logic and setState
    setStartDateInput(event.target.value);
  };

  const startHourInputHandler = (event) => {
    console.log(event.target.value);
    
    let hNum;
    if (event.target.value) {
      hNum = Number(event.target.value);
    }
    console.log(hNum);

    const inHourList = hourList.includes(hNum);
    console.log(inHourList);
    if (!inHourList) {
      setStartHourInput('');
      return;
    }
    if (hNum === 0) {
      setStartHourInput('00');
      return;
    }
    if (hNum < 10) {
      // console.log('0' + event.target.value);
      setStartHourInput('0' + hNum.toString());
    }
    else {
      setStartHourInput(event.target.value);
    }
  }

  const endDateInputHandler = (event) => {
    console.log(event.target.value);

    // some time validation logic and setState
    setEndDateInput(event.target.value);
  };

  const endHourInputHandler = (event) => {
    console.log(event.target.value);
    
    let hNum;
    if (event.target.value) {
      hNum = Number(event.target.value);
    }
    console.log(hNum);

    const inHourList = hourList.includes(hNum);
    console.log(inHourList);
    if (!inHourList) {
      setEndHourInput('');
      return;
    }
    if (hNum === 0) {
      setEndHourInput('00');
      return;
    }
    if (hNum < 10) {
      // console.log('0' + event.target.value);
      setEndHourInput('0' + hNum.toString());
    }
    else {
      setEndHourInput(event.target.value);
    }
  }

  const maxPeopleInputHandler = (event) => {
    setMaxPeopleInput(event.target.value);
  };

  const emailInputHandler = (event) => {
    setEmailInput(event.target.value);
  };

  const useLiveInputHandler = (event) => {
    if (useLiveInput) {
      setUseLiveInput('');
      setMaxPeopleInput(1);
    } else {
      setUseLiveInput('useLive');
    }
  };


  const isValidInputs = () => {
    if (
      !roomNameInput ||
      !startInput ||
      !endInput ||
      endInput <= startInput ||
      endInput <= Date.now() ||
      endInput > Date.now() + roomConfig.endFutureLimit ||
      !maxPeopleInput ||
      // maxPeopleInput < 1 || maxPeopleInput > 100 ||
      (!useLiveInput && maxPeopleInput < 1) || (!useLiveInput && maxPeopleInput > 10) ||
      // !emailInput ||
      !isValidEmail(emailInput) ||
      endInput - startInput > roomConfig.maxDuration ||
      endInputError
    ) {
      return false;
    }
    return true;
  };

  const editRoomHandler = (enteredMeetupData) => {
    console.log(enteredMeetupData);
    setIsLoading(true);
    setCreateMessage('');
    setError('');
    
    let updatedRoomInfo;

    getApi(BACKEND_URL)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);

        return updateRoom(BACKEND_URL, enteredMeetupData);
      })
      // .then((resData) => {
      //   console.log(resData);

      //   updatedRoomInfo = resData.data;
      //   // setRoomCreateData(resData.data);

      //   // setIsLoading(false);
      //   // modalCloseHandler();

      //   const subjectContent = `REMEET-WePL Presentation ${i18next.t("roomFormText50", 'Room update result')}`;
      //   // const subjectContent = `edumeet-webiner-live-presentation-Room update result`;

      //   const textContent = createTextContent(
      //     resData.data.roomId,
      //     resData.data.password, 
      //     resData.data.startLocalTime,
      //     resData.data.endLocalTime,
      //     resData.data.maxPeople,
      //     resData.data.email,
      //     resData.data.creatorPass,
      //     resData.data.useLive,
      //   );

      //   return sendRoomCreateMail(
      //     BACKEND_URL, 
      //     resData.data, 
      //     textContent,
      //     subjectContent
      //   );
      // })
      .then(response => {
        console.log(response);
        setIsLoading(false);
        // modalCloseHandler();
        setCreateMessage(
          <div>
            <div>
              {/* Update Room Success! */}
              {i18next.t("roomFormText32")}
            </div>
            <div>
              {/* Result is sent to your email address. */}
              {i18next.t("roomFormText31")}
            </div>
          </div>
        );

        setGotRoomInfo('');
        setTimeout(() => {
          modalCloseHandler();
          setCreateMessage('');
          setError('');
          setSelectAction('');
        },1000*10);

      })
      .catch((err) => {
        console.log(err);

        const afterActions = () => {
          setIsLoading(false);
          setTimeout(() => {
            // setGotRoomInfo('');
            // setSelectAction('');

            modalCloseHandler();
            setCreateMessage('');
            setError('');
            setSelectAction('');

            createGotStartFormat();
            createGotEndFormat();

          },1000*10);
        }

        if (err.message === 'get-api-failed') {
          setError(i18next.t("roomFormText19", 
          'Service is not available now'
          ));

          afterActions();
          setGotRoomInfo('');
        }
        // if (err.message === 'send-mail-failed') {
        //   setError(i18next.t("roomFormText19", 
        //   'Service is not available now'
        //   ));

        //   const resetRoomData = {
        //     ...updatedRoomInfo,

        //     newStart: gotRoomInfo.start,
        //     newEnd: gotRoomInfo.end,
        //     newMaxPeople: gotRoomInfo.maxPeople,
        //     newEmail: gotRoomInfo.email,
        
        //     newStartLocalTime: transFormLocaleDateTime(gotRoomInfo.start) + ' (' + new Date(gotRoomInfo.start).toLocaleString() +')',
        //     newEndLocalTime: transFormLocaleDateTime(gotRoomInfo.end) + ' (' + new Date(gotRoomInfo.end).toLocaleString() +')',
        //     // newStartLocalTime: dateTimeDisplayOutput(gotRoomInfo.start),
        //     // newEndLocalTime: dateTimeDisplayOutput(gotRoomInfo.end),
        //   };

        //   updateRoom(BACKEND_URL, resetRoomData)
        //     .then(resData => {
        //       console.log(resData);

        //       afterActions();
        //       setGotRoomInfo('');

        //     }).catch(err => {
        //       console.log(err);
        //       afterActions();
        //       setGotRoomInfo('');

        //     });

        // }

        if (err.errorType && err.errorType === 'roomId-exist') {

          setError(i18next.t('roomFormText60',
            `Room's name is already used by another user in that time frame. If you need to change, please delete room and create new room using different room's name`
          ));

          afterActions();
        }

        else {
          // setError(err.message);
          setError(i18next.t("roomFormText26", 
          'Room update failed! Please check your Inputs'
          ));
          afterActions();

        }
        // setError(err.message);
     
      });
  };

  const deleteRoomHandler = (enteredMeetupData) => {
    console.log(enteredMeetupData);
    setIsLoading(true);
    setCreateMessage('');
    setError('');
    getApi(BACKEND_URL)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);

        return deleteRoom(BACKEND_URL, enteredMeetupData);
      })
      .then((resData) => {
        console.log(resData);

        setIsLoading(false);
        // modalCloseHandler();
        setCreateMessage(
          <div>
            <div>
              {/* Delete Room Success! */}
              {i18next.t("roomFormText33")}
            </div>
          </div>
        );
        setGotRoomInfo('');
        setTimeout(() => {
          modalCloseHandler();
          setCreateMessage('');
          setError('');
          setSelectAction('');
        },1000*10);
      })
      .catch((err) => {
        console.log(err);

        const afterActions = () => {
          setIsLoading(false);
          setTimeout(() => {
            modalCloseHandler();
            setCreateMessage('');
            setError('');
            setSelectAction('');
          },1000*10);
        }

        if (err.message === 'get-api-failed') {
          setError(i18next.t("roomFormText19", 
          'Service is not available now'
          ));

          afterActions();
        }
        else {
          setError(i18next.t("roomFormText34",
          'Room deletion failed!'
          ));

          afterActions();
        }

        // setError(err.message);
        // setIsLoading(false);
        // setTimeout(() => {
        //   modalCloseHandler();
        //   setCreateMessage('');
        //   setError('');
        //   setSelectAction('');
        // },1000*10);
        // modalCloseHandler();
        
      });
  };

  const selectActionBody = (
    <Card>
      <div className={classes.form}>
        <GetMeetupContent
          roomData={roomData}
          // startDateInput={startDateInput}
          // startHourInput={startHourInput}
          // startMinuteInput={startMinuteInput}
          // endDateInput={endDateInput}
          // endHourInput={endHourInput}
          // endMinuteInput={endMinuteInput}
        />

        <div className={classes.selectActionTitle}>
          {/* Select Action For This Room */}
          {i18next.t("roomFormText13")}
        </div>
        <div className={classes.actionSelectButtons}>
          <div className={classes.actions}>
            <button
              // disabled={}
              onClick={() => {
                setSelectAction('DELETE');
                //// modal for delete open
                modalOpenHandler();
              }}
            >
              {/* Delete */}
              {i18next.t("generalText3")}
            </button>
          </div>
          <div className={classes.actions}>
            <button
              // disabled={}
              onClick={() => {
                setSelectAction('EDIT');
              }}
            >
              {/* Edit */}
              {i18next.t("generalText5")}
            </button>
          </div>

          {(!qRoomId || !qPassword || !qCreatorPass) && (
            <div className={classes.actions}>
              <button
                // disabled={}
                onClick={() => {
                  // window.location.reload();
                  setGotRoomInfo('');
                }}
              >
                {/* Go Back */}
                {i18next.t("roomFormText55", 'Go Back')}
              </button>
            </div>
          )}

        </div>
      </div>
    </Card>
  );

  let body;
    body = (
      <Card>
        <div className={classes.form}>
          <div className={classes.control}>
            {/* <label htmlFor="room-name">Room Name (8-20 character length of lowercase or number)</label>
            <input
              type="text"
              required
              value={roomNameInput}
              onChange={roomNameInputHandler}
              placeholder='example: next123meeting...'
            /> */}
            <label htmlFor="room-name">
              {/* Room Name (room's name) */}
              Room Name ({i18next.t("roomFormText12")})
              </label>
            <div>{roomNameInput}</div>
          </div>
          <div className={classes.control}>
            <label htmlFor="start-date-time">
              {/* Start Time, up to one year later (Start Date and Time) */}
              {i18next.t("roomFormText3")}, {i18next.t("roomFormText57", 'up to one month later')} (Start Date and Time)
            </label>
            <input 
              type='date'
              onChange={startDateInputHandler}
              // value={startDateInput}
              defaultValue={startDateInput}
              min={transformLocaleDate(Date.now())}
              max={transformLocaleDate(Date.now() + store.roomConfig.endFutureLimit - 1000*60*60*24)}
            />
          </div>
          <div className={classes.timeInputContainer}>
            {/* <span className={classes.timeSelect}>Time</span> */}
            {/* <input 
              className={classes.timeInput} 
              type="text"
              placeholder='0~23' 
              onChange={startHourInputHandler}
              value={startHourInput && Number(startHourInput)}
            /> */}
            <select className={classes.timeSelect} name="hour" id="hour"
              onChange={(event) => {setStartHourInput(event.target.value)}}
              value={startHourInput}
            >
              <option value="">H</option>
              <option value="00">00 (12 AM)</option>
              <option value="01">01 (1 AM)</option>
              <option value="02">02 (2 AM)</option>
              <option value="03">03 (3 AM)</option>
              <option value="04">04 (4 AM)</option>
              <option value="05">05 (5 AM)</option>
              <option value="06">06 (6 AM)</option>
              <option value="07">07 (7 AM)</option>
              <option value="08">08 (8 AM)</option>
              <option value="09">09 (9 AM)</option>
              <option value="10">10 (10 AM)</option>
              <option value="11">11 (11 AM)</option>
              <option value="12">12 (12 PM)</option>
              <option value="13">13 (1 PM)</option>
              <option value="14">14 (2 PM)</option>
              <option value="15">15 (3 PM)</option>
              <option value="16">16 (4 PM)</option>
              <option value="17">17 (5 PM)</option>
              <option value="18">18 (6 PM)</option>
              <option value="19">19 (7 PM)</option>
              <option value="20">20 (8 PM)</option>
              <option value="21">21 (9 PM)</option>
              <option value="22">22 (10 PM)</option>
              <option value="23">23 (11 PM)</option>
            </select>

            <span className={classes.timeSeparator}>:</span>
            <select className={classes.timeSelect} name="minutes" id="minutes"
              onChange={(event) => {setStartMinuteInput(event.target.value)}}
              value={startMinuteInput}
            >
              <option value="">--</option>
              <option value="00">00</option>
              <option value="30">30</option>
            </select>
            <span> </span>
            {/* <select className={classes.timeSelect} name="xm" id="xm"
              onChange={(event) => {setStartXMInput(event.target.value)}}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select> */}
          </div>

          <div className={classes.control}>
            {/* {startDateInput}{', '} {startHourInput} {':'} {startMinuteInput}
            <br/> */}
            {/* {startInput && startHourInput
              ? startDateInput + ' ' + new Date(startInput).toTimeString() 
              : null
            } */}
            {startInput && startHourInput
              && dateTimeDisplayOutput(startDateInput, startInput)
            }
          </div>

          {trialEndTime && startInput > trialEndTime
            && (<div className={classes.inputError}>
              {i18next.t("subText45", "Start Time should be before end of free trial subscription.")}
            </div>)
          }



          <div className={classes.control}>
            <label htmlFor="start-date-time">
              {/* End Time, up to one year later (End Date and Time) */}
              {/* {i18next.t("roomFormText4")}, {i18next.t("roomFormText57", 'up to one year later')} (End Date and Time) */}
              {/* End Time, maximum 6 hours (End Date and Time) */}
              {i18next.t("roomFormText4")}, maximum 6 hours (End Date and Time) 
            </label>
            <input 
              type='date'
              onChange={endDateInputHandler}
              // value={endDateInput}
              defaultValue={endDateInput}
              min={transformLocaleDate(Date.now())}
              max={transformLocaleDate(Date.now() + store.roomConfig.endFutureLimit - 1000*60*60*24)}
              // defaultValue='2021-01-01'
            />
          </div>
          <div className={classes.timeInputContainer}>
            {/* <span className={classes.timeSelect}>Time</span> */}
            {/* <input 
              className={classes.timeInput} 
              type="text"
              placeholder='0~23'
              onChange={endHourInputHandler}
              value={endHourInput && Number(endHourInput)}
            /> */}
            <select className={classes.timeSelect} name="hour" id="hour"
              onChange={(event) => {setEndHourInput(event.target.value)}}
              value={endHourInput}
            >
              <option value="">H</option>
              <option value="00">00 (12 AM)</option>
              <option value="01">01 (1 AM)</option>
              <option value="02">02 (2 AM)</option>
              <option value="03">03 (3 AM)</option>
              <option value="04">04 (4 AM)</option>
              <option value="05">05 (5 AM)</option>
              <option value="06">06 (6 AM)</option>
              <option value="07">07 (7 AM)</option>
              <option value="08">08 (8 AM)</option>
              <option value="09">09 (9 AM)</option>
              <option value="10">10 (10 AM)</option>
              <option value="11">11 (11 AM)</option>
              <option value="12">12 (12 PM)</option>
              <option value="13">13 (1 PM)</option>
              <option value="14">14 (2 PM)</option>
              <option value="15">15 (3 PM)</option>
              <option value="16">16 (4 PM)</option>
              <option value="17">17 (5 PM)</option>
              <option value="18">18 (6 PM)</option>
              <option value="19">19 (7 PM)</option>
              <option value="20">20 (8 PM)</option>
              <option value="21">21 (9 PM)</option>
              <option value="22">22 (10 PM)</option>
              <option value="23">23 (11 PM)</option>
            </select>
            <span className={classes.timeSeparator}>:</span>
            <select className={classes.timeSelect} name="minutes" id="minutes"
              onChange={(event) => {setEndMinuteInput(event.target.value)}}
              value={endMinuteInput}
            >
                <option value="">--</option>
                <option value="00">00</option>
                <option value="30">30</option>
            </select>
            <span> </span>
            {/* <select className={classes.timeSelect} name="xm" id="xm"
              onChange={(event) => {setEndXMInput(event.target.value)}}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select> */}
          </div>

          <div className={classes.control}>
            {/* {startDateInput}{', '} {startHourInput} {':'} {startMinuteInput}
            <br/> */}
            {/* {endInput && endHourInput
              ?  endDateInput + ' ' + new Date(endInput).toTimeString() 
              : null
            } */}
            {endInput && endHourInput
              && dateTimeDisplayOutput(endDateInput, endInput)
            }
          </div>

          {roomConfig && ((endInput - startInput) > roomConfig.maxDuration) &&
            <div className={classes.inputError}>
              {i18next.t("roomFormText71", 'maximum 6 hours')}
            </div>
          }

          <div className={classes.inputError}>{endInputError}</div>

          {!useLiveInput && (
            <div className={classes.control}>
              <label htmlFor="max-people">
                {i18next.t("roomFormText5", 'Maximum Participatns')}, {i18next.t("roomFormText62", 'less than 10')} (Maximum number of participants)
                {/* {i18next.t("roomFormText5")}, {i18next.t("roomFormText58", 'less than 100')} (Maximum number of participants) */}
              </label>
              <input
                type="number"
                required
                min="1"
                setp="1"
                value={maxPeopleInput}
                onChange={maxPeopleInputHandler}
              />
            </div>
          )}

          {/* {!userLoginEmail && maxPeopleInput > 3 &&
            <div>some-message-for-log-sub</div>
          } */}

          {/* {(!haveActiveSubscription && maxPeopleInput >= 3) &&
            <MessageForSubscription />
          } */}

          {/* {isMiniSubscripiton && maxPeopleInput > 6
            && (<div className={classes.inputError}>
                Mini Plan's {i18next.t("subText44", "maximum participant number is 6")}
              </div>)
          } */}

          <LiveSelectForm 
            showAboutLive={showAboutLive}
            setShowAboutLive={setShowAboutLive}
            useLiveInput={useLiveInput}
            useLiveInputHandler={useLiveInputHandler}
          />


          <div className={classes.control}>
            <label htmlFor="email">
              {/* Email address (Email address) */}
              {i18next.t("roomFormText6")} (Email address)
            </label>
            {emailInput}
            {/* <input
              type="email"
              required
              value={emailInput}
              onChange={emailInputHandler}
            /> */}
          </div>
          {/* <div className={classes.control}>
          <label htmlFor='tel'>tes-input</label>
          <input 
            type='tel'
            // required 
          />
        </div> */}
        <div className={classes.reserveButtons}>
          <div>
            <button className={classes.resetButton}
              // disabled={}
              onClick={() => {
                setSelectAction('');
                createGotStartFormat();
                createGotEndFormat();
              }}
            >
              {/* Cancel */}
              {i18next.t("generalText1")}
            </button>
          </div>
          <div className={classes.actions}>
            <button
              disabled={!isValidInputs() || isLoading}
              onClick={() => {
                // props.onAddMeetup(roomData);
                // createRoomHandler(roomData);
                // setSelectAction('EDIT');
                modalOpenHandler();
              }}
            >
              {/* Edit */}
              {i18next.t("generalText5")}
            </button>
          </div>
        </div>

        {!isValidInputs() && 
            <div className={classes.actions}>
              {/* invalid inputs */}
              {i18next.t("roomFormText54", 'Invalid Inputs')}
            </div>
        }


          {isLoading && <div><Loader /></div>}
          <div className={classes.resultMessage}>
            {error}
          </div>
          <div className={classes.resultMessage}>
            {createMessage}
          </div>

          {/* <div style={{overflow:"auto"}}>
            result-create
            {JSON.stringify(roomCreateData)}
          </div> */}
        </div>

      </Card>
    );

  return (
  <Fragment>
    {showModal && <Backdrop />}
    {showModal &&
      <Modal
        onConfirm={editRoomHandler}
        onDelete={deleteRoomHandler}
        onCancel={modalCloseHandler}
        roomData={roomData}
        isLoading={isLoading}
        selectAction={selectAction}
        setSelectAction={setSelectAction}
        createMessage={createMessage}
        error={error}
      >
        {selectAction === 'EDIT' 
          ? (
              <EditMeetupModalContent 
                roomData={roomData}
                startDateInput={startDateInput}
                startHourInput={startHourInput}
                startMinuteInput={startMinuteInput}
                endDateInput={endDateInput}
                endHourInput={endHourInput}
                endMinuteInput={endMinuteInput}
              />
            )
          : (<GetMeetupContent roomData={roomData} />)
        }
      </Modal>
    }

    {gotRoomInfo && !selectAction ? selectActionBody : null}

    {gotRoomInfo && selectAction ? body : null}

    {!gotRoomInfo 
      ? <GetMeetupForm 
          setGotRoomInfo={setGotRoomInfo}
          qRoomId={qRoomId}
          qPassword={qPassword}
          qCreatorPass={qCreatorPass}
        />
      : null
    }
    

    
  </Fragment>);
}

export default EditMeetupForm;
