import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// import { useTranslation } from "next-i18next";
import i18next from "i18next";

import { useStore } from "../../../hooks-store/store";

import NewIcon from "../../../images/icons/newBadgeIcon-2-white-48.png";
import EditIcon from "../../../images/icons/editIcon-white-48.png";
import PlannerIcon from "../../../images/icons/planner-white-48.png";

import { roomPageUrl, weplPageUrl } from "../../../App";
import classes from "./NavigationItems.module.css";
// import { style } from './MainNavigation-style';

function NavigationItems(props) {
  const { navType, setShowMobileNav } = props;
  const [store, dispatch] = useStore();

  // console.log(store);

  // const { t } = useTranslation("common");

  const userLoginEmail = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";
  
  const homeLink = `/`;
  const editLink = `/edit-room`;

  let navContainerStyle;
  let navListClassName;

  if (navType === "mobile") {
    navContainerStyle = {
      flexDirection: "column",
      alignItems: "center",
    };

    navListClassName = classes.mobileNavList;
  }

  let navigationItemsBody;
  navigationItemsBody = (
    <ul
      className={classes.navListContainer}
      style={navContainerStyle}
      onClick={() => {
        setShowMobileNav(false);
      }}
    >
      <li className={navListClassName}>
        <NavLink to={editLink}>
          <img
            className={classes.mainNavigationIcon}
            src={EditIcon}
            height="20"
            alt="edit room icon"
          />
          {i18next.t("roomFormText53", "Edit Room")}
          {/* {i18n?.t('roomFormText53')} */}
        </NavLink>
      </li>
      <li className={navListClassName}>
        <NavLink to={homeLink}>
          <img
            className={classes.mainNavigationIcon}
            src={NewIcon}
            height="20"
            alt="new room icon"
          />
          {i18next.t("roomFormText52", "New Room")}
        </NavLink>
      </li>
      {userLoginEmail && (
        <li className={navListClassName}>
          <NavLink to='/user-room'>
            <img
              className={classes.mainNavigationIcon}
              src={PlannerIcon}
              height="17.5"
              alt="planner icon"
            />
            {/* {i18next.t("roomFormText52", "New Room")} */}
            {/* User Room */}
            {i18next.t('userRoom.text01', 'Your Rooms')}
          </NavLink>
        </li>
      )}
      <li className={navListClassName}>
        <a
          href={weplPageUrl + "/your-presentation"}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          REMEET-WePL
        </a>
      </li>
    </ul>
  );

  return <Fragment>{navigationItemsBody}</Fragment>;
}

export default NavigationItems;
