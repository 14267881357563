import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
// import { userLoginEmail } from "../../App";

import { useStore } from "../../hooks-store/store";
import { getSubscriptionState } from "../../utils/stripe";

import Card from "../../components/ui/Card";
import Loader from "../../components/Loader/Loader";
import MainNavigation from "../../components/layout/mainNavigation/MainNavigation";
import { isValidEmail } from "../../utils/new-meetup";

import { BACKEND_URL } from "../../App";

import classes from "./Contact.module.css";

function Contact(props) {
  // console.log('Footer.js-props', props);
  // const { t } = useTranslation("common");

  const [state, dispatch] = useStore();

  const [showConfirm, setShowConfirm] = useState(false);
  const [formSending, setFormSending] = useState(false);
  const [sendResult, setSendResult] = useState('');
  const [inValidFormMessage, setInValidFormMessage] = useState('');
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [messageInput, setMessageInput] = useState("");

  const formInput = {
    name: nameInput,
    email: emailInput,
    title: titleInput,
    message: messageInput,
  };

  // console.log('formInput', formInput);

  const userLoginEmail = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";

  const subscriptionLink = `/subscription?customer-email=${userLoginEmail}`;

  const subscriptions = state.stripeData.subscriptions;
  const subscriptionStatus = getSubscriptionState(subscriptions);
  // console.log(subscriptionStatus);

  const messagePlacehokder = `Yor message here... 
(If there are answers to your message, an email will be sent to your email address. 
But it will take longer and will be English.)
`
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('sendResult', sendResult);
  },[sendResult])

  const showConfirmHandler = () => {
    window.scrollTo(0, 0);
    setShowConfirm(!showConfirm);
  };

  useEffect(() => {
    setInValidFormMessage('');
    // setSendResult('');
    // console.log('messageInput', messageInput)
  },[nameInput, emailInput, titleInput, messageInput]);


  const checkFormValidity = () => {
    if (!isValidEmail(emailInput)) {
      console.log('invalid email input')
      setInValidFormMessage('Invalid Form input, please check your inputs');
      return false;
    }
    if (!messageInput.trim() || !titleInput.trim()) {
      setInValidFormMessage('Invalid Form, please check your inputs');
      return false;
    }

    return true;
  }




  const sendContactMailHandler = (formInput) => {
    setFormSending(true);

    fetch(BACKEND_URL + "/send-mail/contact", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formInput),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("send form failed");
        }
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        setSendResult('Send Form Success');
        setFormSending(false);
      })
      .catch((err) => {
        console.log(err);
        setSendResult('Send Form Failed');
        setFormSending(false);
      });
  };

  let contactBody;
  contactBody = (
    <Card>
      <div className={classes.inputForm}>
        <section>
          <button>
            <Link to="/">
              Go Back to home
            </Link>
          </button>
        </section>
        <section>
          <h2>Contact Form</h2>
          <ul>
            <li>
              <label htmlFor="username">Your Name</label>
              <input
                type="text"
                id="username"
                name="username"
                value={nameInput}
                onChange={(event) => {
                  setNameInput(event.target.value);
                }}
              />
            </li>
            <li>
              <label htmlFor="email">Your E-Mail Address (required)</label>
              <input
                type="email"
                id="email"
                name="email"
                value={emailInput}
                onChange={(event) => {
                  setEmailInput(event.target.value);
                }}
                required
              />
            </li>
            <li>
              <label htmlFor="title">Title (required, less than 100 characters)</label>
              <input
                type="text"
                id="title"
                name="title"
                minLength="1"
                maxLength="100"
                placeholder=""
                value={titleInput}
                onChange={(event) => {
                  setTitleInput(event.target.value);
                }}
                required
              />
            </li>
            <li>
              <label htmlFor="message">Your Message (required, less than 1000 characters)</label>
              <textarea
                id="message"
                name="message"
                minLength="1"
                maxLength="1000"
                rows="6"
                placeholder={messagePlacehokder}
                value={messageInput}
                onChange={(event) => {
                  setMessageInput(event.target.value);
                }}
                required
              ></textarea>
            </li>
          </ul>
        </section>

        <section id="form-actions" className={classes.formActions}>
          {/* <button type="reset" class="btn-empty">
          Clear
        </button> */}
          <button
            type="submit"
            className={classes.btnFilled}
            onClick={() => {
              if (checkFormValidity()) {
                showConfirmHandler();
              } else {
                console.log('invalid form');
              }
            }}
          >
            Send Message
          </button>
        </section>
        <section>
          <strong>{inValidFormMessage}</strong>
        </section>
      </div>
    </Card>
  );

  let confirmBody;
  confirmBody = (
    <Card>
      <div className={classes.inputForm}>
        <section>
          <h2>Please Confirm Your Inputs</h2>
          <ul>
            <li>
              <label htmlFor="username">Your Name</label>
              <strong>{nameInput}</strong>
            </li>
            <li>
              <label htmlFor="email">Your E-Mail Address</label>
              <strong>{emailInput}</strong>
            </li>
            <li>
              <label htmlFor="title">Title</label>
              <strong>{titleInput}</strong>
            </li>
            <li>
              <label htmlFor="message">Your Message</label>
              <strong className={classes.messageDisplay}>
                {messageInput}
              </strong>
            </li>
          </ul>
        </section>

        <section>
          <button 
            className={classes.btnEmpty}
            disabled={formSending}
            onClick={showConfirmHandler}
          >
            Go Back
          </button>
          <button
            type="submit"
            className={classes.btnFilled}
            disabled={formSending}
            onClick={() => {
              sendContactMailHandler(formInput);
            }}
          >
            Send Message
          </button>
        </section>
        <section>
          {formSending && <Loader />} 
          <strong>
            {sendResult}
          </strong>
        </section>
      </div>
    </Card>
  );

  return <Fragment>
    {/* <MainNavigation /> */}
    {showConfirm ? confirmBody : contactBody}
    </Fragment>;
}

export default Contact;
